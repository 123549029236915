import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"

const ListTitle = styled.h3`
  margin-bottom: 0.5em;
`

const ListItems = styled.ul`
  list-style-type: none;
  margin-left: 1em;
  li::before {
    content: "";
    background-color: hsla(0, 0%, 0%, 0.8);
    height: 4px;
    width: 4px;
    position: absolute;
    transform: translate(-12px, 11px);
    @media (prefers-color-scheme: dark) {
      background-color: hsla(0, 0%, 100%, 0.8);
    }
  }
`

const ContactPage = location => (
  <Layout location={location}>
    <div>
      <ListTitle>Over there</ListTitle>
      <ListItems>
        <li>me[at]nochevolta.me</li>
        <li>
          <a
            href="https://twitter.com/NocheVolta"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            href="https://github.com/nochevolta"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/nochevolta/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </a>
        </li>
      </ListItems>
    </div>
  </Layout>
)

export default ContactPage
